import { X } from 'lucide-react'
import React, { useState } from 'react'
import Modal from '../modal/modal';

export default function ButtonDeleteItem({Service, Item, OnDelete}) {

    const [isModalOpenDelete, setIsModalOpenDelete] = useState(false);

    const handleSubmitDelete = async ()  => {
        await Service.Delete(Item.id)
        if(OnDelete){
          await OnDelete();
        }
        setIsModalOpenDelete(false);
      };

    const onCloseDelete = () => {
        setIsModalOpenDelete(false);
    };

    return (
      <>
        <button className="text-white ml-2 -mt-1" onClick={()=>{setIsModalOpenDelete(true)}}>
            <X color='#9D9B9B' />
        </button>
        {isModalOpenDelete && (
            <Modal handleSubmit={handleSubmitDelete} onClose={onCloseDelete} openByTable={isModalOpenDelete} >
                <p>Tem certeza que deseja excluir?</p>
            </Modal>
        )}
      </>
   
  )
}
