import { useContext, useEffect, useState } from "react";
import TeamService from "../../services/team";
import { AuthContext } from "../../context/auth";
import FormTeam from "./FormTeam";
import { teamEntityForm } from "./formEntity";
import TeamCard from "./componentsSpecify/teamCard";
import Card from "../../components/card/card";
import Loading from "../../components/loading/page";

export default function TeamPage() {
  const { user } = useContext(AuthContext);
  const teamService = new TeamService();

  const [teams, setTeams] = useState([]);
  const [isLoading, setIsLoading] = useState(true); 
  const entityForm = teamEntityForm(user?.id);
  const [formData, setFormData] = useState(entityForm);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileSelected = (file) => {
    setSelectedFile(file);
  };

  async function fetchAllTeams() {
    setIsLoading(true);
    const teamsData = await teamService.getAllTeams();
    setTeams(teamsData);
    setIsLoading(false); 
  }

  useEffect(() => {
    fetchAllTeams();
  }, []);

  if (isLoading) {
    return (
     <Loading />
    );
  }

  return (
    <Card className={"mt-4 p-4 bg-primary-light grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4"}>
      {teams.map((team, index) => (
        <TeamCard
          key={index}
          TeamsData={team}
          IsOwnerTeam={user?.id}
          Service={teamService}
          FormChildren={
            <FormTeam
              formData={formData}
              setFormData={setFormData}
              onFileSelected={handleFileSelected}
            />
          }
          FetchData={fetchAllTeams}
        />
      ))}
    </Card>
  );
}
