import React from 'react'
import { Input } from '../../../input'

export default function FormInviteUser({formData, setFormData}) {
  return (
    <div>
        <Input
            id="email"
            type={"email"}
            label="Email do Usuário"
            placeholder="Alpha"
            formData={formData}
            setFormData={setFormData}
        />
    </div>
  )
}
