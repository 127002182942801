import { useContext, useState } from 'react';
import ButtonClick from "../../../components/button/buttonClick";
import CardFixed from "../../../components/card/cardFixed";
import Modal from "../../../components/modal/modal";
import { TimeOfTournament } from "../../../utils/calculateFuntions";
import TeamService from '../../../services/team';
import { AuthContext } from "../../../context/auth";
import TeamCardBasicSelect from '../../team/componentsSpecify/teamCardBasicSelect';
import TournamentParticipantsService from '../../../services/tournament/tournamentParticipants';
import { NewParticipantInTournament } from '../formEntity';
import ToastError from '../../../components/toast/toastError';

export default function InfoTournamentCard({ tournament }) {
  const { user } = useContext(AuthContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [teamsByUser,setTeamsByUser] = useState([]);
  const teamService = new TeamService()
  const [selectedTeamId, setSelectedTeamId] = useState(null);

  async function listTeamsByUser(userId) {
    try {
      const teams = await teamService.getTeamsByCreatorId(userId);
      setTeamsByUser(teams)
    } catch (error) {
      console.error(error)
    }
  }

  const handleOpenModal = async () => {
    if(user?.id)
    {
      await listTeamsByUser(user.id)
      setIsModalOpen(true);
    } else {
      ToastError("Realize o login para participar!")
    }
    
  };

  const handleCloseModal = () => {
    setSelectedTeamId(null);
    setIsModalOpen(false);
  };

  const handleSelectTeam = (id) => {
    setSelectedTeamId(id);
  };

  const handleConfirmSelection = async () => {
    try {
      const newParticipant = NewParticipantInTournament(tournament.id,selectedTeamId)
      await new TournamentParticipantsService().insertParticipant(newParticipant);
    } catch (error) {
      console.error(error)
    } finally {
      handleCloseModal();
    }
   
  };

  return (
    <CardFixed Title={"Informações Gerais"} className="col-start-1 col-span-2 h-[150px] w-full p-2">
      <div className="flex items-center justify-between ml-2">
        <div className="flex items-center">
          <img
            src={tournament.image}
            alt={""}
            className="w-[150px] h-[90px] object-cover rounded-md mr-4"
          />
          <div>
            <h1 className="font-semibold text-white text-2xl">{tournament.name}</h1>
            <p className="font-semibold text-white text-sm mt-2">
              {TimeOfTournament(tournament.date_init)}
            </p>
          </div>
        </div>
        <ButtonClick Title={"Participar"} onClick={handleOpenModal} />
        {isModalOpen && 
          <Modal openByTable={true} onClose={handleCloseModal} handleSubmit={handleConfirmSelection} Title="Selecione um time">
            <div className="flex flex-col max-h-72 overflow-y-auto gap-2">
              {teamsByUser.map((item, index) => (
                <TeamCardBasicSelect 
                  key={index}  
                  TeamsData={item}
                  isSelected={item.id === selectedTeamId}
                  onSelect={handleSelectTeam}
                />
              ))}
            </div>
          </Modal>
        }
      </div>
    </CardFixed>
  );
}
