import ApiService from "../api/apiService"

class TournamentParticipantsService extends ApiService {
    constructor(){
        super("tournaments/participants")
    }

    async getParticipantsByIdTournament(IdTournament){
        return await this.get("id_tournament",`id_tournament=${IdTournament}`);
    }

    async insertParticipant(data){
        return await this.post(data)
    }


}

export default TournamentParticipantsService;
