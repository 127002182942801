import React from 'react'
import Card from '../../../components/card/card'
import { Crown } from 'lucide-react'

export default function OwnerTeam({ UserData }) {
  return (
    <Card className={"bg-primary-light w-full rounded-lg p-2 "}>
        <div className='flex flex-row'>
            <img
                src={UserData.raw_user_meta_data.avatar_url} 
                alt="Logo do Time"
                className=" rounded-full w-10 h-10"
                />
                <p className='text-left p-2 font-bold text-white'>{UserData.raw_user_meta_data.full_name}</p>
                <Crown color='#9D9B9B' size={20} className="ml-auto mt-2 mr-2"/>
        </div>
    </Card>
  )
}
