export function UploadFileToFormData(formData, selectedFile){
    const formDataToSend = new FormData();
        Object.keys(formData).forEach((key) => {
            formDataToSend.append(key, formData[key]);
        });
        if (selectedFile) {
            formDataToSend.append("file", selectedFile);
        }
        const formDataObject = {};
        formDataToSend.forEach((value, key) => {
            formDataObject[key] = value;
        });
    return formDataObject;
}