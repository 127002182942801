import { Plus } from "lucide-react";
import Card from "../../../card/card";
import Modal from "../../../modal/modal";
import { useContext, useState } from "react";
import FormEntityInviteUserTeam from "./formEntityInviteUserTeam";
import FormInviteUser from "./formInviteUser";
import UserService from "../../../../services/user";
import InviteTeamService from "../../../../services/invites";
import { AuthContext } from "../../../../context/auth";
import ToastSucess from "../../../toast/toastSucess";

export default function ButtonInviteUserTeam({TeamData}) {
    const { user } = useContext(AuthContext); 
    const [OpenModalInvite, setOpenModalInvite] = useState(false);
    const [userEmail, setUserEmail] = useState({email:""})

    const userService = new UserService()
    const inviteService = new InviteTeamService()

    async function HandleInvite(){
        if(userEmail.email === TeamData.creator_id.raw_user_meta_data.email){
            ToastSucess("Usuário dono do time")
            return;
        }
        const userDb = await userService.getUserByEmail(userEmail.email);
        if(userDb){
            const userAlreadyTeam = await inviteService.CheckInviteTeamParticipant(userDb[0].id,TeamData.id)
            if(userAlreadyTeam.length > 0){
                ToastSucess("Usuário já está no time")
                return;
            }
            const formInvite = FormEntityInviteUserTeam(user.id,userDb[0].id,TeamData.id)
            await inviteService.InviteUser(formInvite);
        }
    }

    return (
        <>
            <Card className="bg-primary-dark w-full rounded-md p-2 cursor-pointer text-center text-white font-bold mt-2 border-2 border-dashed border-primary-light">
            <button onClick={() => setOpenModalInvite(true)}>
                <div className="flex flex-row gap-2">
                    <p>Convidar um novo participante</p>
                    <Plus  color='#9D9B9B' />
                </div>
            </button>
            </Card>
           {OpenModalInvite &&
            <Modal handleSubmit={HandleInvite} onClose={() => setOpenModalInvite(false)} openByTable={true}>
                <FormInviteUser formData={userEmail} setFormData={setUserEmail}/>
            </Modal>}
        </>
    );
}
