import ApiService from "../api/apiService";
import ParticipantService from "../participants";

class TeamService extends ApiService {
    constructor(){
        super("teams")
    }

    async getAllTeams(){
        return await this.get()
    }

    async getTeamsByCreatorId(id){
        return await this.get(":id", "", {id: id})
    }

    async getTeamsByParticipantID(id){
        return new ParticipantService().getTeamsByParticipantID(id)
    }
    
    async Create(data){
        return await this.post("create",data)
    }

    async Delete(id){
        return await this.delete(id)
    }

    async Update(Id,data){
        return await this.put(Id,data)
    }

}

export default TeamService;
