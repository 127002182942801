import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { ImageUp, X } from 'lucide-react';

export default function UploadFile({ onFileSelected, initialImage }) {
    const [dataURL, setDataURL] = useState(initialImage || null);

    const onDrop = useCallback(acceptedFiles => {
        const file = acceptedFiles[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setDataURL(reader.result);
                onFileSelected(file); 
            };
            reader.readAsDataURL(file);
        }
    }, [onFileSelected]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    return (
            <div className="zone">
                {dataURL ? (
                    <div className="relative w-[155px] h-[155px] mb-4">
                        <img src={dataURL} alt="Preview" className="w-full h-full object-cover rounded-lg" />
                        <button 
                            onClick={() => setDataURL(null)} 
                            className="absolute top-1 right-1 bg-primary-dark text-white rounded-full p-1 hover:bg-red-600"
                        >
                            <X size={16} />
                        </button>
                    </div>
                ) : (
                    <div className="drop-zone border-dashed border-2 border-gray-400 rounded-lg w-[155px] h-[155px] flex items-center justify-center cursor-pointer mb-4" {...getRootProps()}>
                        <input {...getInputProps()} />
                        {isDragActive ? (
                            <p className="drop-files">Solte o arquivo aqui...</p>
                        ) : (
                            <ImageUp size={90} color='#9D9B9B' />
                        )}
                    </div>
                )}
        </div>
    );
}
