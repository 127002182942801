import React, { useEffect, useState } from 'react';
import ParticipantService from '../../../services/participants';
import Card from '../../../components/card/card';
import { LogOut, X } from 'lucide-react';
import ButtonInviteUserTeam from '../../../components/userProfile/Invites/teams/buttonInviteUserTeam';

export default function ParticipantsTeam({ TeamData, OwnerTeam, FetchData }) {
    const [participants, setParticipants] = useState([]);
    const Service = new ParticipantService();

    async function getParticipants(idTeam) {
        const participants = await Service.getParticipantsByTeamId(idTeam);
        setParticipants(participants);
    }

    async function removeParticipants(Participant,Team){
        await Service.DeleteParticipant(Participant,Team)
        if(FetchData){
            FetchData()
        }
        await getParticipants(TeamData.id)
    }

    useEffect(() => {
        getParticipants(TeamData.id);
    }, [TeamData.id]);
   
    return (
        <>
            {participants && participants.map((participant, index) => (
                <Card key={index} className="bg-primary-light w-full rounded-lg p-2">
                    <div className="flex flex-row">
                        <img
                            src={participant.participant_id.raw_user_meta_data.avatar_url} 
                            alt="Logo do Time"
                            className="rounded-full w-10 h-10"
                        />
                        <p className="text-left p-2 font-bold text-white">
                            {participant.participant_id.raw_user_meta_data.full_name}
                        </p>
                       {OwnerTeam === TeamData.creator_id.id && <X color="#ef6b6b" className="ml-auto mt-2 mr-2 cursor-pointer" onClick={() => {removeParticipants(participant.participant_id.id,TeamData.id)}} />}
                       {OwnerTeam === participant.participant_id.id && <LogOut size={20} color="#ef6b6b" className="ml-auto mt-2 mr-2 cursor-pointer" onClick={() => {removeParticipants(participant.participant_id.id,TeamData.id)}}/>}
                    </div>
                </Card>
            ))}
            {participants && participants.length < 5 && OwnerTeam === TeamData.creator_id.id && <ButtonInviteUserTeam TeamData={TeamData}/>}
        </>
    );
}
