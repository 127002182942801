import ApiService from "../api/apiService";


class TournamentService extends ApiService {
  constructor() {
    super("tournaments"); // Define a URL base e a tabela de torneios
  }


  async getAllTournamentsNoParams() {
    return await this.get();
  }

  async getAllTournamentsByGame(game) {
    return await this.get("game",`game=${game}`);
  }

  async getAllTournaments(game = null) {
    return game ? await this.getAllTournamentsByGame(game) : await this.getAllTournamentsNoParams();
  }

  async getTournamentById(id) {
    return await this.getById(id);
  }
}

export default TournamentService;
