import React from 'react'
import Card from '../../../components/card/card'

export default function TeamCardBasic({ TeamsData }) {
  return (
    <Card className="p-4 rounded-2xl shadow-lg w-full h-13">
        <div className='flex flex-row '>
        <img
            src={TeamsData.img_link}
            alt="Logo do Time"
            className=" rounded-lg w-10 h-10 object-cover"
          />
        <p className="text-lg font-bold text-white p-1 ml-2">{TeamsData.name}</p>
        </div>
    </Card>
  )
}
