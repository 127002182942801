import Card from "../../../components/card/card";
import OwnerTeam from "./ownerTeam";
import ButtonDeleteItem from "../../../components/button/buttonDeleteItem";
import ButtonEditItem from "../../../components/button/buttonEditItem";
import { teamEntityFormEdit } from "../formEntity";
import ParticipantsTeam from "./participantsTeam.js";

export default function TeamCard({ TeamsData, IsOwnerTeam, Service, FormChildren, FetchData}) {
  return (
    <Card className="p-4 rounded-2xl shadow-lg w-[565px] h-[340px]">
      <div className="flex flex-row gap-4 w-full">
        <div className="items-center w-64">
          <img
            src={TeamsData.img_link}
            alt="Logo do Time"
            className=" rounded-lg w-[155px] h-[155px] object-cover"
          />
        </div>
        <div className="flex flex-col w-full">
          <div className="flex flex-row mb-3 ml-2">
            <p className="text-lg font-bold text-white">{TeamsData.name}</p>
            {IsOwnerTeam === TeamsData.creator_id.id && (
              <div className="flex ml-auto "> 
                <ButtonEditItem FormChildren={FormChildren} Service={Service} FormEdit={teamEntityFormEdit} Item={TeamsData} OnEdit={FetchData}/>
                <ButtonDeleteItem Item={TeamsData} Service={Service} OnDelete={FetchData}/>
              </div>
            )}
            </div>
            <div className="space-y-2 max-h-72 overflow-y-auto p-2">
              <OwnerTeam UserData={TeamsData.creator_id} />
              <ParticipantsTeam TeamData={TeamsData} OwnerTeam={IsOwnerTeam} FetchData={FetchData} />
            </div>
        </div>
      </div>
    </Card>
  );
}
