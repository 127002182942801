import React from 'react';
import Card from '../../../components/card/card';
import { Check } from 'lucide-react';

export default function TeamCardBasicSelect({ TeamsData, isSelected, onSelect }) {
  return (
    <Card className="p-4 rounded-2xl shadow-lg w-full h-13">
      <div className="flex flex-row items-center">
        <img
          src={TeamsData.img_link}
          alt="Logo do Time"
          className="rounded-lg w-10 h-10 object-cover"
        />
        <p className="text-lg font-bold text-white p-1 ml-2">{TeamsData.name}</p>
        
        <div className="ml-auto">
          <label className="relative cursor-pointer flex items-center">
            <input
              type="checkbox"
              checked={isSelected}
              onChange={() => onSelect(TeamsData.id)}
              className="sr-only"
            />
            <div
              className={`w-6 h-6 rounded-md border-2 flex items-center justify-center transition-all duration-200 ${
                isSelected ? 'bg-secondary-light border-secondary-light' : 'bg-primary-dark border-primary-light'
              }`}
            >
              {isSelected && (
                <Check color='white' size={18} />
              )}
            </div>
          </label>
        </div>
      </div>
    </Card>
  );
}
