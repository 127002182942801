import React, { useState } from 'react';
import CardBackgroundPage from '../../../components/card/cardBackgroundPage';
import CreateEditTeam from '../componentsSpecify/createEditTeam';
import ListTeams from '../componentsSpecify/listTeams';

export default function TeamByIdUser() {
    const [newTeamCreated, setNewTeamCreated] = useState(false);
    
    function handleNewTeamCreated() {
        setNewTeamCreated(prev => !prev); // Alterna o estado para forçar o efeito
    }

    return (
        <CardBackgroundPage>
            <CreateEditTeam onTeamCreated={handleNewTeamCreated} />
            <ListTeams newTeamCreated={newTeamCreated} />
        </CardBackgroundPage>
    );
}
