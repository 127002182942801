import { Settings } from 'lucide-react';
import React, { useState } from 'react';
import Modal from '../modal/modal';
import { CloudinaryUpload } from '../../services/Cloudinary/service';
import { UploadFileToFormData } from '../../utils/uploadFileFormData';

export default function ButtonEditItem({ FormChildren, Service, FormEdit, Item, OnEdit }) {
    const [isModalOpen, setIsModalOpen] = useState(false); 
    const [selectedRow, setSelectedRow] = useState(null);  
    const [selectedFile, setSelectedFile] = useState(null);

    const handleEditButton = () => {
        const editForm = FormEdit(Item);
        setSelectedRow(editForm); 
        setIsModalOpen(true); 
    };

    const handleSubmit = async () => {
        if (selectedRow) {
            let updatedData = { ...selectedRow };
            if (selectedFile) {
                updatedData = UploadFileToFormData(updatedData,selectedFile);
            }
            await Service.Update(updatedData.id, updatedData); 
            if(OnEdit){
                await OnEdit(true);
            }
            setIsModalOpen(false); 
        }
    };

    const resetForm = () => { 
        setIsModalOpen(false); 
    };

    return (
        <div>
            <button className="text-white ml-2" onClick={handleEditButton}>
                <Settings color='#9D9B9B' />
            </button>
            {isModalOpen && (
                <Modal handleSubmit={handleSubmit} onClose={resetForm} openByTable={true}>
                    {FormChildren && React.cloneElement(FormChildren, { formData: selectedRow, setFormData: setSelectedRow, onFileSelected: setSelectedFile})}
                </Modal>
            )}
        </div>
    );
}
