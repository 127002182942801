import React from 'react';
import { Input } from '../../components/input';
import UploadFile from '../../components/input/uploadFile';

export default function FormTeam({ formData, setFormData, onFileSelected }) {

    return (
        <div className='flex gap-6'>
            <UploadFile onFileSelected={onFileSelected} initialImage={formData.img_link} />
            <div className='w-full'>
                <Input
                    id="name"
                    label="Nome do time"
                    placeholder="Alpha"
                    formData={formData}
                    setFormData={setFormData}
                />
            </div>
        </div>
    );
}
