import React from 'react'
import CardFixed from '../../../components/card/cardFixed'

export default function CreatorTournamentCard() {
  return (
    <CardFixed Title={"Organização"} className="col-start-4 row-start-5 h-full w-full">
      
    </CardFixed>
  )
}
