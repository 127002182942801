import React, { useEffect, useState } from 'react'
import CardFixed from '../../../components/card/cardFixed'
import TeamCardBasic from '../../team/componentsSpecify/teamCardBasic'
import TournamentParticipantsService from '../../../services/tournament/tournamentParticipants'

export default function TeamsTournamentCard({tournament}) {
  const [TeamsByTournament, setTeamsByTournament] = useState([])
  const participantsTournamentService = new TournamentParticipantsService() 

  async function getTeamsByTournament(idTournament) {
    try {
      const participants = await participantsTournamentService.getParticipantsByIdTournament(idTournament)
      setTeamsByTournament(participants);
    } catch (error) {
      console.error(error)
    }
  }

    useEffect(()=>{
      getTeamsByTournament(tournament.id)
    },[tournament.id])

 
  return (
    <CardFixed Title={"Times inscritos"} className="row-span-4 col-start-4  h-[full] w-full overflow-y-auto">
      <div className='flex flex-col gap-2'>
      {TeamsByTournament.map((item, index) => (
        <TeamCardBasic key={index} TeamsData={item.team_id} />
      ))}
      </div>
    </CardFixed>
  )
}
