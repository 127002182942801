import SupabaseService  from "../supabase/actions";

class ParticipantService extends SupabaseService {
    constructor(){
        super("teams_participants")
    }

    fieldParticipants(){
        return `id, 
        team_id (
            id
        ),
        participant_id (
            id,
            email,
            raw_user_meta_data
        )
        `
    }

    fieldTeamCard(){
        return `id, 
        team_id (
            id ,
            created_at, 
            name, 
            img_link,
            creator_id (
                id,
                nickname,
                raw_user_meta_data
            )
        )
        `
    }

    participantsByTeamId(id){
        return [
            { field: 'team_id', value: id },
          ]
    }

    teamByParticipantID(id){
        return [
            { field: 'participant_id', value: id },
          ]
    }

    removeUserOfTeam(Participant,Team){
        return [
            { field: 'team_id', value: Team },
            { field: 'participant_id', value: Participant },
        ]
    }

    teamParticipantExits(Participant,Team){
        return [
            { field: 'team_id', value: Team },
            { field: 'participant_id', value: Participant },
        ]
    }

    async checkTeamParticipantExits(Participant,Team){
        return await this.selectSpecify(this.fieldParticipants(),this.teamParticipantExits(Participant,Team))
    }

    async getParticipantsByTeamId(id){
        return await this.selectSpecify(this.fieldParticipants(),this.participantsByTeamId(id))
    }

    async getTeamsByParticipantID(id){
        return await this.selectSpecify(this.fieldTeamCard(),this.teamByParticipantID(id))
    }

    async NewParticipantTeam(data){
        return await this.insert(data)
    }

    async DeleteParticipant(Participant,Team){
        return await this.deleteSpecify(this.removeUserOfTeam(Participant,Team))
    }

}

export default ParticipantService;
