import { BellDot } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import TeamCardInvite from '../../../../pages/team/componentsSpecify/teamCardInvite';
import InviteTeamService from '../../../../services/invites';

export default function AcceptInviteTeam({ UserID }) {
    const [isOpen, setIsOpen] = useState(false);
    const [invites, setInvites] = useState([]);
    const inviteService = new InviteTeamService();
    
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    
    async function getInvites(Id) {
        const invitesUser = await inviteService.getInvitesByUserId(Id);
        setInvites(invitesUser);
    }

    useEffect(() => {
        getInvites(UserID);
    }, [UserID]);

    return (
        <div className='p-4 relative'>
            <div className="relative inline-block">
                <BellDot color='#9D9B9B' className='hover:cursor-pointer' onClick={toggleDropdown} />
                {invites.length > 0 && (
                    <span className="absolute -top-2 -right-2 bg-red-500 text-white text-xs font-semibold rounded-full h-5 w-5 flex items-center justify-center">
                        {invites.length}
                    </span>
                )}
            </div>
            <div
                className={`absolute z-10 right-0 mt-2 w-96 bg-primary-dark border border-primary-light rounded-lg shadow-lg p-2 transition-transform transform ${
                    isOpen ? 'scale-100 opacity-100' : 'scale-75 opacity-0 pointer-events-none'
                }`}
                style={{ transitionDuration: '200ms' }}
            >
                <p className='font-semibold p-1'>Convites de Time</p>
                {invites.length > 0 ? (
                    invites.map((invite, index) => (
                        <TeamCardInvite key={index} InviteData={invite} getInvites={getInvites} UserID={UserID} />
                    ))
                ) : (
                    <p className="text-center p-2">Nenhum convite disponível</p>
                )}
            </div>
        </div>
    );
}
