import SupabaseService  from "../supabase/actions";

class UserService extends SupabaseService {
    constructor(){
        super("users")
    }


    fieldsUser(){
        return `*`
    }

    userByEmail(email){
        return [
            { field: 'email', value: email }
          ]
    }

    async getUserByEmail(email){
        return await this.selectSpecify("*",this.userByEmail(email))
    }
  
}

export default UserService;
