import { useContext, useEffect, useState } from "react";
import TeamCard from "./teamCard";
import { AuthContext } from "../../../context/auth";
import TeamService from "../../../services/team";
import { teamEntityForm } from "../formEntity";
import FormTeam from '../FormTeam';

export default function ListTeams({ newTeamCreated }) {
    const { user } = useContext(AuthContext);
    const [myTeamsOwn, setMyTeamsOwn] = useState([]);
    const [myTeams, setMyTeams] = useState([]);

    const teamService = new TeamService();
    const entityForm = teamEntityForm(user.id);
    const [formData, setFormData] = useState(entityForm);
    const [selectedFile, setSelectedFile] = useState(null);
    
    const handleFileSelected = file => {
        setSelectedFile(file);
    };

    const fetchDataOwn = async () => {
        try {
            return await teamService.getTeamsByCreatorId(user.id);
        } catch (error) {
            console.error("Erro ao buscar times:", error);
        }
    };

    const fetchDataAll = async () => {
        try {
            return await teamService.getTeamsByParticipantID(user.id);
        } catch (error) {
            console.error("Erro ao buscar times:", error);
        }
    }

    async function fetchData() {
        try {
            const [allMyTeams,ownTeams] = await Promise.all([fetchDataAll(),fetchDataOwn()])
            setMyTeams(allMyTeams);
            setMyTeamsOwn(ownTeams);
        } catch (error) {
            console.error(error)
        }
        
    }

    useEffect(() => {
        fetchData();
    }, [user.id, newTeamCreated]); 


    return (
        <div className="p-4 space-y-6">
            <div>
                <p className="text-lg font-bold mb-4 p-2">Meus Times</p>
                {myTeamsOwn.length === 0 ? (
                    <p className="p-2">Nenhum time encontrado.</p>
                ) : (
                    <div className={`grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4`}>
                        {myTeamsOwn.map((team, index) => (
                            <TeamCard
                                key={index}
                                TeamsData={team}
                                IsOwnerTeam={user.id}
                                Service={teamService}
                                FormChildren={
                                    <FormTeam
                                        formData={formData}
                                        setFormData={setFormData}
                                        onFileSelected={handleFileSelected}
                                    />
                                }
                                FetchData={fetchData}
                            />
                        ))}
                    </div>
                )}
            </div>
            <div>
                <p className="text-lg font-bold mb-4 p-2">Times que Participo</p>
                {myTeams.length === 0 ? (
                    <p className="p-2">Nenhum time encontrado.</p>
                ) : (
                    <div className={`grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4`}>
                        {myTeams.map((team, index) => (
                            <TeamCard
                                key={index}
                                TeamsData={team.team_id}
                                IsOwnerTeam={user.id}
                                Service={teamService}
                                FormChildren={
                                    <FormTeam
                                        formData={formData}
                                        setFormData={setFormData}
                                    />
                                }
                                FetchData={fetchData}
                            />
                        ))}
                    </div>
                )}
            </div>
        </div>
    );

}
