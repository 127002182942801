import React, { useContext, useState } from 'react';
import Modal from '../../../components/modal/modal';
import FormTeam from '../FormTeam';
import { AuthContext } from '../../../context/auth';
import { teamEntityForm } from '../formEntity';
import TeamService from '../../../services/team';
import ToastError from '../../../components/toast/toastError';
import { UploadFileToFormData } from '../../../utils/uploadFileFormData';

export default function CreateEditTeam({ onTeamCreated }) {
    const { user } = useContext(AuthContext);
    const entityForm = teamEntityForm(user.id);
    const [formData, setFormData] = useState(entityForm);
    const [selectedFile, setSelectedFile] = useState(null);
    const teamService = new TeamService();

    const handleFileSelected = file => {
        setSelectedFile(file);
    };

    async function handleSubmit(){
        const formDataToSend = UploadFileToFormData(formData,selectedFile);
        try {
            await teamService.Create(formDataToSend);
            resetForm()
            onTeamCreated();

        } catch (error) {
            console.error("Erro ao criar o time:", error);
            ToastError("Erro ao criar o time. Por favor, tente novamente.");
        }
    }

    const resetForm = () =>{
        setFormData(entityForm);
        setSelectedFile(null);
    }

    return (
        <div className='p-4'>
            <Modal buttonTitle={"Criar meu time"} Title={"Novo time"} handleSubmit={handleSubmit} onClose={resetForm}>
                <FormTeam formData={formData} setFormData={setFormData} onFileSelected={handleFileSelected}/>
            </Modal>
        </div>
    );
}
