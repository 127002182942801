import { JoinTeam } from "../../pages/team/formEntity";
import ParticipantService from "../participants";
import SupabaseService  from "../supabase/actions";

class InviteTeamService extends SupabaseService {
    constructor(){
        super("invites_team")
    }

    fieldInvite(){
        return ` id ,
        invited_by (
            id,
            nickname,
            raw_user_meta_data
        ),
        team_id (
            id,
            img_link,
            name
        )
        `
    }

    InvitesByUserId(id){
        return [
            { field: 'invited_user', value: id },
            { field: 'status', value: "PENDING" }
          ]
    }

    async getInvitesByUserId(id){
        return await this.selectSpecify(this.fieldInvite(),this.InvitesByUserId(id))
    }

    async InviteUser(data){
        return await this.insert(data)
    }

    async AnswerInvite(data,UserId,Status,message){
       const form = { id: data.id, status: Status }
       if(data !== undefined){
           if(Status === "ACCEPTED"){
                await new ParticipantService().NewParticipantTeam(JoinTeam(data,UserId))
                return await this.update(data.id,form,message)
           }
           return await this.update(data.id,form,message)
       }
   }

   async CheckInviteTeamParticipant(Participant,Team){
        return await new ParticipantService().checkTeamParticipantExits(Participant,Team);
   }

}

export default InviteTeamService;
