export function teamEntityForm (idCreator) {
    return {
        name:"",
        creator_id: idCreator,
    }
}

export function teamEntityFormEdit (record) {
    return {
        id: record.id,
        name: record.name,
        creator_id: record.creator_id.id,
        img_link: record.img_link
    }
}

export function AnswerInvite(answer){
    return {
        status: answer
    }
}

export function JoinTeam(Data, UserID){
    return {
        team_id: Data.team_id.id,
        participant_id: UserID
    }
}