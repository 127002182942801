import React from 'react';
import Card from '../../../components/card/card';
import { Check, Crown, X } from 'lucide-react';
import InviteTeamService from '../../../services/invites';

export default function TeamCardInvite({ InviteData, getInvites, UserID }) {
    const inviteService = new InviteTeamService();

    async function AcceptSubmit() {
        await AnswerSubmit(InviteData,"ACCEPTED","Convite aceito");
        await getInvites(UserID);
    }

    async function DeclineSubmit() {
        await AnswerSubmit(InviteData,"DECLINE", "Convite recusado");
        await getInvites(UserID);
    }

    async function AnswerSubmit(data,status,message) {
        await inviteService.AnswerInvite(data,UserID,status,message);
    }

    return (
        <Card className={"w-full bg-primary-light p-4 rounded-md mt-2"}>
            <div className='flex flex-row gap-1'>
                <img
                    src={InviteData.team_id.img_link}
                    className="w-20 h-20 rounded-md object-cover"
                    alt="User avatar"
                />
                <div className='flex flex-col'>
                    <p className='p-1 text-white'>{InviteData.team_id.name}</p>
                    <div className='flex flex-row'>
                        <Crown className='mt-2 ml-1' size={15} color='#9D9B9B' />
                        <p className='p-1 '>{InviteData.invited_by.raw_user_meta_data.full_name}</p>
                    </div>
                </div>
                <div className='flex flex-row ml-auto mt-6'>
                    <Check color='#93e1bd' className='cursor-pointer' onClick={AcceptSubmit} />
                    <X color='#ef6b6b' className='cursor-pointer' onClick={DeclineSubmit} />
                </div>
            </div>
        </Card>
    );
}
